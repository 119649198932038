.center {
  text-align: center;
}
.hasError {
  height: 200px;
  margin-top: 100px;
}
.navigation nav div.Polaris-ButtonGroup {
  margin: 0 auto;
  max-width: 80px;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
}

.loading-spinner.not-in-app-bridge svg {
  fill: #449da7;
}

.loading-spinner-text {
  margin-top: 16px;
}

.loading-spinner-text.not-in-app-bridge {
  color: #6d7175;
}
